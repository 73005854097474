import React from 'react'
import { graphql } from 'gatsby'
import { extract } from '../../../utils/graphql'

import WysiwygWTout from '../../../components/Templates/WysiwygWTout'
import NoTranslation from '../../../components/Templates/NoTranslation'

import { localizePath } from '../../../../config/i18n'
import { withPreview } from 'gatsby-source-prismic'

const CommunityPage = ({ data, pageContext }) => {
  const { prismicTheCommunity } = data
  const page = prismicTheCommunity
  if (!page) return <NoTranslation></NoTranslation>
  const hydratedData = {
    lang: pageContext.locale,
    seo: extract.seo(page),
    mantelData: extract.mantel(page, {
      parent: {
        path: localizePath(pageContext.locale, '/work-with-us'),
        text: 'Work With Us',
      },
    }),
    bg: 2,
    toutData: extract.tout(page),
  }
  return <WysiwygWTout {...hydratedData}></WysiwygWTout>
}

export default withPreview(CommunityPage)

export const pageQuery = graphql`
  query TheCommunityByLocale($locale: String!) {
    prismicTheCommunity(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        seo_keywords {
          keyword {
            text
          }
        }
        seo_description {
          text
        }
        mantel_title {
          text
        }
        tout_title {
          text
        }
        tout_image {
          url
          alt
        }
        tout_content {
          html
        }
        tout_button_text {
          text
        }
        tout_button_url {
          url
          link_type
        }
      }
    }
  }
`
