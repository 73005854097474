import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { screen } from '../../../utils'

const socialIconsWrapClasses = `
  flex
  items-center
`

const SocialIcon = styled.a`
  ${ tw`
    flex
    items-center
    justify-center
    h-full
  ` }

  svg {
    max-width: 100%;
    max-height: 100%;
    width: 30px;
    height: 30px;
    fill: black;

    ${ screen('lg') } {
      width: 20px;
      height: 20px;
    }
  }
`

const headingClasses = `
  font-sans
  font-bold
  text-white
  uppercase
  text-sm
`

const Follow = ({ heading, icons, black = false, oneLine = false }) => (
  <div>
    <div className={ classNames(headingClasses, {
      'text-black': black,
      'text-grey': !black,
      'text-center lg:text-left mb-4': !oneLine,
      'mb-2': oneLine
    }) }>{ heading }</div>
    <div className={ classNames(socialIconsWrapClasses, {
      'justify-center flex-wrap lg:flex-no-wrap': !oneLine,
      'justify-start flex-no-wrap': oneLine
    }) }>
      { icons.map((icon, i) => {
        if (!icon.url) return

        return (
          <SocialIcon
            key={ `footer-social-icon-${ i }` } href={ icon.url }
            className={ classNames({
              'lg:mr-2': (icons.length > (i + 1)) && !oneLine,
              'mr-2': (icons.length > (i + 1)) && oneLine,
              'w-1/3 lg:w-auto': !oneLine,
              'lg:mb-0 mb-4': true,
              'text-black hover:text-grey': black,
              'text-grey hover:text-white': !black
            })}
            target="_blank"
            rel="noopner"
          >
            <icon.shape innerFill={black ? 'white' : ''} />
          </SocialIcon>
        )
      })}
    </div>
  </div>
)

export default Follow
