import React from 'react'
import SmartLink from '../SmartLink'

const colorMap = {
  Magenta: 'text-green-3',
  Purple: 'text-purple',
  Orange: 'text-orange',
  Teal: 'text-teal',
}

const icons = {
  Page: () => (
    <svg
      className="absolute z-10"
      css="top: 10px; left: 12px;"
      width="18"
      height="22"
      viewBox="0 0 18 22"
    >
      <g fill="#FFF" fill-rule="evenodd">
        <path
          d="M5 15a1 1 0 000 2h3a1 1 0 000-2H5zm0-4a1 1 0 000 2h7a1 1 0 000-2H5zM1.857 0h6.879c.355 0 .698.125.969.355l4.764 4.029A1.5 1.5 0 0115 5.529v12.554C15 19.874 14.979 20 13.143 20H1.857C.021 20 0 19.874 0 18.083V1.917C0 .126.021 0 1.857 0z"
          opacity=".298"
        />
        <path d="M5 15a1 1 0 000 2h3a1 1 0 000-2H5zm0-4a1 1 0 000 2h7a1 1 0 000-2H5zM3.857 2h7.879c.355 0 .699.125.969.354l4.764 4.03A1.5 1.5 0 0118 7.529v12.554C18 21.874 17.979 22 16.143 22H3.857C2.021 22 2 21.874 2 20.083V3.917C2 2.126 2.021 2 3.857 2z" />
      </g>
    </svg>
  ),
  Folder: () => (
    <svg
      className="absolute z-10"
      css="top: 10px; left: 11px;"
      width="22"
      height="19"
      viewBox="0 0 22 19"
    >
      <g fill="#FFF" fill-rule="evenodd">
        <path
          d="M1.65 18.7h18.7c.912 0 1.65-.74 1.65-1.65V4.95a1.65 1.65 0 00-1.65-1.65H8.8L5.983.483A1.648 1.648 0 004.817 0H1.65C.74 0 0 .738 0 1.65v15.4c0 .91.74 1.65 1.65 1.65"
          opacity=".303"
        />
        <path
          d="M7.1 9.279l-1.278 1.278a.824.824 0 000 1.166L7.1 13a.825.825 0 001.167 0l1.277-1.278a.824.824 0 000-1.166L8.267 9.279a.825.825 0 00-1.167 0M14.014 9.279l-1.279 1.278a.824.824 0 000 1.166L14.014 13a.825.825 0 001.167 0l1.278-1.278a.826.826 0 000-1.166l-1.278-1.278a.825.825 0 00-1.167 0"
          opacity=".303"
        />
        <path d="M10.557 12.736l-1.278 1.278a.824.824 0 000 1.166l1.278 1.278a.825.825 0 001.167 0l1.277-1.278a.824.824 0 000-1.166l-1.277-1.278a.825.825 0 00-1.167 0M10.557 5.821L9.279 7.1a.824.824 0 000 1.166l1.278 1.279a.825.825 0 001.167 0l1.277-1.279a.824.824 0 000-1.166l-1.277-1.278a.825.825 0 00-1.167 0" />
      </g>
    </svg>
  ),
  Wrench: () => (
    <svg
      className="absolute z-10"
      css="top: 10px; left: 12px;"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="#FFF" fill-rule="evenodd">
        <path d="M14.188.237l-3.292 3.292a2.252 2.252 0 000 3.184l1.592 1.59c.88.88 2.304.88 3.182 0l3.293-3.292c.61 2.246.041 4.734-1.709 6.484-2.058 2.058-5.138 2.48-7.642 1.276l-5.77 5.77A2.25 2.25 0 11.66 15.357l5.77-5.77c-1.206-2.503-.783-5.583 1.276-7.642C9.455.195 11.943-.373 14.188.237" />
        <path
          d="M14.984 2.624l1.592 1.592a1.127 1.127 0 010 1.592L15.023 7.36c-.439.44-1.152.44-1.592 0L11.84 5.769a1.126 1.126 0 010-1.591l1.553-1.554a1.127 1.127 0 011.592 0"
          opacity=".301"
        />
      </g>
    </svg>
  ),
  Checklist: () => (
    <svg
      className="absolute z-10"
      css="top: 10px; left: 12px;"
      width="16"
      height="22"
      viewBox="0 0 16 22"
    >
      <g fill="#FFF" fill-rule="evenodd">
        <path d="M6.204 14.462a.948.948 0 00.671.288.95.95 0 00.67-.288l3.834-3.833a.927.927 0 000-1.342.926.926 0 00-1.342 0L6.875 12.45l-1.246-1.245c-.336-.384-.959-.384-1.342 0a.925.925 0 000 1.341l1.917 1.917zM4 2.5V2H2C.894 2 0 2.895 0 4v16a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2h-2v.5A1.5 1.5 0 0110.5 4h-5A1.5 1.5 0 014 2.5z" />
        <path
          d="M7 1a1 1 0 112 0h1.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5H7z"
          opacity=".3"
        />
      </g>
    </svg>
  ),
}

const ResourceTiles = ({ tiles }) => (
  <div className="-mx-1 flex flex-wrap md:flex-no-wrap">
    {tiles.map((tile) => {
      const Icon = icons[tile.icon] || icons.Folder

      return (
        <SmartLink
          to={tile.link}
          className="transition relative
            w-full md:w-1/4
            mb-2 md:mb-0
            mx-1 px-3 pt-10 pb-3
            overflow-hidden
            rounded-lg bg-light-grey-1
            font-sans
            hover:shadow-md
            no-underline"
          css={`
            &:hover .circles {
              transform: translate3d(-39%, -39%, 0) scale(1.16) !important;
            }

            &:hover .arrow {
              transform: translateX(5px);
            }
          `}
        >
          <div className={colorMap[tile.color] || colorMap.Magenta}>
            <Icon />
            <svg
              width="180"
              height="180"
              viewBox="0 0 180 180"
              className="circles transition"
              css={`
                position: absolute;
                top: 0;
                left: 0;
                transform: translate3d(-39%, -39%, 0);
              `}
            >
              <g className="fill-current" fill-rule="evenodd">
                <path
                  d="M90 0c49.706 0 90 40.291 90 90 0 49.705-40.294 90-90 90-49.705 0-90-40.295-90-90C0 40.29 40.295 0 90 0"
                  opacity=".051"
                />
                <path
                  d="M89.5 30c33.413 0 60.5 27.084 60.5 60.5 0 33.413-27.087 60.5-60.5 60.5S29 123.913 29 90.5C29 57.084 56.087 30 89.5 30"
                  opacity=".104"
                />
                <path d="M89.5 61c16.292 0 29.5 13.206 29.5 29.5 0 16.292-13.208 29.5-29.5 29.5S60 106.792 60 90.5C60 74.206 73.208 61 89.5 61" />
              </g>
            </svg>
            <h4 className="flex items-center justify-between mb-1 text-xl font-normal">
              {tile.title}
              <svg
                className="arrow transition"
                width="18"
                height="15"
                viewBox="0 0 18 15"
              >
                <g className="fill-current" fill-rule="evenodd">
                  <path
                    d="M80-160c49.706 0 90 40.291 90 90 0 49.705-40.294 90-90 90-49.705 0-90-40.295-90-90 0-49.709 40.295-90 90-90"
                    opacity=".051"
                  />
                  <path d="M.883 8.358A1 1 0 011 6.364h13.586L9.929 1.707a1 1 0 01-.083-1.32l.083-.094a.999.999 0 011.414 0l6.364 6.364a.999.999 0 010 1.414l-6.364 6.364a1 1 0 11-1.414-1.414l4.657-4.657H1z" />
                </g>
              </svg>
            </h4>
            <p className="text-sm text-dark-grey-2 leading-normal">
              {tile.copy}
            </p>
          </div>
        </SmartLink>
      )
    })}
  </div>
)

export default ResourceTiles
