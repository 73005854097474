import React from 'react'
import PageWrap from '../../Layouts/Global/PageWrap'
import Mantel from '../../General/Mantel'
import Tout from '../../General/Tout'
import WysiwygContent from '../../General/WysiwygContent'
import ResourceTiles from '../../General/ResourceTiles'

const tiles = [
  {
    title: 'Articles',
    copy:
      'The latest developer-related news on Algorand. Includes new feature launches, events, and technical overviews',
    color: 'Magenta',
    icon: 'Page',
  },
  {
    title: 'Articles',
    copy:
      'The latest developer-related news on Algorand. Includes new feature launches, events, and technical overviews',
    color: 'Purple',
    icon: 'Folder',
  },
  {
    title: 'Articles',
    copy:
      'The latest developer-related news on Algorand. Includes new feature launches, events, and technical overviews',
    color: 'Orange',
    icon: 'Checklist',
  },
  {
    title: 'Articles',
    copy:
      'The latest developer-related news on Algorand. Includes new feature launches, events, and technical overviews',
    color: 'Teal',
    icon: 'Wrench',
  },
]

const WysiwygWTout = ({
  seo,
  bg,
  mantelData,
  toutData,
  resourceTiles,
  content,
}) => (
  <PageWrap bg={bg} seo={seo}>
    <Mantel {...mantelData} />
    <div className="mx-auto max-w-3xl pt-0 pb-7 lg:py-8">
      <Tout {...toutData} />
    </div>
    {resourceTiles && (
      <div className="mx-auto max-w-3xl mb-8">
        <ResourceTiles tiles={resourceTiles} />
      </div>
    )}
    <div className="max-w-xl mx-auto">
      <WysiwygContent content={content} />
    </div>
  </PageWrap>
)

export default WysiwygWTout
