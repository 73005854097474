import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { screen } from '../../../utils/index'
import SecondaryHeading from '../Headings/SecondaryHeading'
import GradientButton from '../Buttons/GradientButton'
import Follow from '../Follow'
import VideoPlay from '../../Shapes/VideoPlay'

const ToutImage = styled.div`
  ${tw`
    mb-2
    w-full
    relative
    lg:w-1/2
    sm:flex-no-shrink
    lg:mr-5
    lg:mb-0
  `}

  ${screen('lg')} {
    max-width: 479px;
  }
`

const ToutHtml = styled.div`
  ${tw`
      font-sans
      font-light
      leading-normal
      text-lg
      text-dark-grey-2
  `}

  p,
  img {
    ${tw`
      mb-3
    `}
  }

  a {
    ${tw`
      text-green-3
    `}
  }
`

const toutWrapClasses = `
  lg:flex
  lg:items-center
`
const DescriptionParagraph = styled.div`
  ${tw`
  text-base 
  font-light 
  text-charcoal
  leading-normal
  font-sans
  `}
`

const playIconWrapClasses = `
  text-white
  v-center
  absolute
  w-full
  text-center
`

const playIconBgClasses = `
  absolute
  pin-l
  pin-t
  w-full
  h-full
  bg-black-trans
`

const Tout = ({ title, text, html, img, button, follow, isVideo = false }) => {
  const LinkComponent = button.external ? 'a' : Link

  return (
    <div className={toutWrapClasses}>
      <ToutImage>
        <LinkComponent
          className="no-underline w-full"
          to={button.route}
          href={button.route}
          target={button.external ? '_blank' : null}
        >
          <img src={img.src} alt={img.alt} className="block w-full" />
          {isVideo && (
            <div className={playIconBgClasses}>
              <div className={playIconWrapClasses}>
                <VideoPlay />
              </div>
            </div>
          )}
        </LinkComponent>
      </ToutImage>
      <div>
        <div className="mb-2">
          <SecondaryHeading>{title}</SecondaryHeading>
        </div>
        <div className="mb-2">
          {html && <ToutHtml dangerouslySetInnerHTML={{ __html: html }} />}
          {text && <DescriptionParagraph>{text}</DescriptionParagraph>}
        </div>
        <LinkComponent
          className="no-underline"
          to={button.route}
          href={button.route}
          target={button.external ? '_blank' : null}
        >
          <GradientButton>{button.text}</GradientButton>
        </LinkComponent>
        {follow && (
          <div className="mt-4 lg:mr-auto lg:mt-7">
            <Follow {...follow} black={true} oneLine={true}></Follow>
          </div>
        )}
      </div>
    </div>
  )
}

export default Tout
